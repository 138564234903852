import Vue from 'vue';
import App from './App';
import Vuetify from 'vuetify/lib'
import router from './router';
import store from './store';
import HIcon from '@/components/HIcon';
import HIcon2 from '@/components/HIcon2';
import VCalendar from 'v-calendar';
import i18n from './i18n';

import 'material-design-icons-iconfont';
import 'vuetify/dist/vuetify.min.css';

import themes from './assets/css/themes';
import icons from './assets/icons/icons';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(VueTelInput);
Vue.component('h-icon', HIcon);
Vue.component('h-icon2', HIcon2);
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

const vuetify = new Vuetify({
  icons: icons,
  theme: {
    options: {
      customProperties: true,
    },
    themes: themes,
  },
});

/*
Add the Google ReCaptcha Script to the HTML
 */
let s = document.createElement( 'script' );
s.setAttribute( 'src', `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECATPCHA_SITE_KEY}`);
document.body.appendChild( s );


new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
