<template>
	<div style="width:100%; height: 100%; min-height: 100%;">
		<public-form v-if="resourceType === 'Form'" :pod-url="podUrl" :account-key="accountKey" :unique-template-id="resourceId" :custom-fonts="customFonts"></public-form>
		<meeting-renderer v-if="resourceType === 'Meeting'" :pod-url="podUrl" :account-key="accountKey" :meeting-id="resourceId" :custom-fonts="customFonts"></meeting-renderer>
	</div>
</template>

<script>
	import MeetingRenderer from '@/modules/meetings/MeetingRenderer';
	import ResourceResolverService from "@/modules/resolver/ResourceResolverService";
	import PublicForm from "@/modules/forms/PublicForm";
	export default {
		name: 'RequestResolver',

		props: ['parts'],

		components: {PublicForm, MeetingRenderer },

		data: function () {
			return {
				customFonts: [],
				resourceType: null,
				uriParts: this.parts.split('/'),
			};
		},

		mounted() {
			this.init();
		},

		beforeDestroy() {},

		methods: {
			init: function(){
				let resourceResolverService = new ResourceResolverService(this.podUrl);
				resourceResolverService.getCustomFonts(this.accountKey).then((res) => {
					this.customFonts.splice(0);
					this.customFonts.push(... res.data);
					this.lookupResourceType();
				})
			},

			lookupResourceType: function(){
				let resourceResolverService = new ResourceResolverService(this.podUrl);
				resourceResolverService.getResourceType(this.accountKey,this.resourceId).then((res) => {
					this.resourceType = res.data;
				}).catch((err) => {
					console.log(err);
					this.$router.push('/NotFound');
				})
			}
		},

		computed: {
			accountKey: function () {
				return this.uriParts[1];
			},

			resourceId: function () {
				return this.uriParts[2];
			},

			podUrl: function () {
				return 'https://pod' + this.uriParts[0] + '.' + process.env.VUE_APP_BASE_URL + '/api';
			},
		},
	};
</script>

<style scoped lang="scss"></style>
