import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from "@/views/NotFound";
import RequestResolver from "@/modules/resolver/RequestResolver";
import CompletedForm from "@/modules/forms/CompletedForm";

Vue.use(VueRouter)

const routes = [
  {
    path: '/NotFound',
    name: 'NotFound',
    component: NotFound,
    meta: {
      insecure: true,
    }
  },
  {
    path: '/:podId/completedForm',
    name: 'CompletedForm',
    component: CompletedForm,
    props: true,
    meta: {
      insecure: true,
    }
  },
  {
    path: '/:parts+',
    name: 'RequestResolver',
    component: RequestResolver,
    props: true,
    meta: {
      insecure: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
