import axios from 'axios';

export default class ResourceResolverService{

    constructor(podUrl) {
        this.podUrl = podUrl;
    }

    getClient(baseUrl) {
        const options = {
            baseURL: baseUrl,
            withCredentials: false,
        };
        return axios.create(options);
    }

    getResourceType(urlPath,resourceId) {
        let params =  {
            urlPath: urlPath ,
            resourceId: resourceId,
        }

        return this.getClient(this.podUrl).get('/resource/lookup',{params:params})
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }

    getCustomFonts(urlPath){
        let params =  {
            urlPath: urlPath
        }

        return this.getClient(this.podUrl).get('/resource/lookup/fonts',{params:params})
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
}
