<template>
	<div style="width:100%; height:100%; min-height: 100%">
		<form-v1 v-if="schemaVersion === 1" :form="form" :font="font" :in-frame="inFrame" :in-portal="inPortal" :complete="complete" ref="form" @complete="saveForm($event)"></form-v1>
		<form-v2 v-if="schemaVersion === 2" :form="form" :font="font" :in-frame="inFrame" :in-portal="inPortal" :complete="complete" ref="form" @complete="saveForm($event)"></form-v2>
	</div>
</template>

<script>
	import PublicFormService from '@/modules/forms/PublicFormService';
	import WebFontLoader from 'webfontloader';
	import FormV1 from "@/modules/forms/v1/FormV1";
	import FormV2 from "@/modules/forms/v2/FormV2";
	import {Base64} from "js-base64";

	export default {
		name: 'PublicForm',

		props: ['podUrl', 'accountKey', 'uniqueTemplateId','customFonts'],

		components: { FormV1, FormV2 },

		data: function () {
			return {
				schemaVersion: 0,
				complete: false,
				form: null,
				font: null,
				formService: new PublicFormService(this.podUrl),
			};
		},

		mounted() {
			this.getFormInfo();
		},

		beforeDestroy() {},

		methods: {
			getFormInfo: function () {
				this.$store.commit('startLoading');

				this.formService
					.getFormInfo(this.accountKey, this.uniqueTemplateId)
					.then((res) => {
						this.form = res.data;
						this.font = this.form.font;
						this.schemaVersion = this.form.schemaVersion;
						this.loadFont(this.form.font);
					})
					.catch((err) => {
						console.log(err);
						this.$router.push('/NotFound');
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			loadFont: function(fontName){
				let font = this.customFonts.find(f => f.fontName === fontName);
				if(font){
					let newFont = new FontFace(font.fontName, `url(${font.fontUrl})`);
					newFont.load().then(() => {
						document.fonts.add(newFont);
						this.setFont();
					})
				}else {
					WebFontLoader.load({
						google: {
							families: [`${fontName}:200,300,400,500,600`],
						},
						active: this.setFont,
					});
				}
			},

			setFont: function () {
				this.font = this.form.font;
			},

			saveForm: function (result) {
				let formResult = result.formResult;
				let files = result.files;

				formResult.formName = this.form.name;
				formResult.sourceUrl = this.sourceUrl;
				formResult.opportunityId =  this.opportunityId;

				this.$store.commit('startLoading');

				window.grecaptcha.ready(() => {
					window.grecaptcha
						.execute(process.env.VUE_APP_RECATPCHA_SITE_KEY, { action: 'submit' })
						.then((reCaptchaToken) => {
							this.formService
								.submitFormInfo(this.form.accountId, this.uniqueTemplateId, formResult, files, reCaptchaToken)
								.then(() => {
									this.complete = true;

									try {
										localStorage.removeItem(`${this.form.accountId}.${this.form.uniqueTemplateId}`);
									}catch(err){
										console.log(err);
									}

									try {
										parent.postMessage('FORM_SUBMITTED', '*');
										parent.postMessage('[Result]' + Base64.encode(JSON.stringify(formResult)), '*');
									}catch(err){
										console.log(err);
									}
								})
								.catch((err) => alert(err.response.message.data))
								.finally(() => this.$store.commit('stopLoading'));
						});
				});
			}

		},

		computed: {
			sourceUrl: function () {
				return this.$route.query.sourceUrl ? this.$route.query.sourceUrl : window.location.href;
			},

			opportunityId: function () {
				return this.$route.query.opportunityId;
			},
			
			inFrame: function () {
				return !!this.$route.query.inFrame;
			},

			inPortal: function(){
				return !!this.$route.query.inPortal;
			}
		},
	};
</script>

<style  lang="scss">
</style>
