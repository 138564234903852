<template>
	<div class="mb-5">
		<v-container class="ma-0 pa-0">
			<v-row dense align="center" v-if="!hideDefaultNameEmail">
				<v-col cols="2" class="row-format ml-3"
					><v-btn :style="`color: ${headersContrast}`" icon @click="$emit('back')"
						><v-icon large>arrow_back</v-icon></v-btn
					></v-col
				>
				<v-col cols="8" class="font-18 brand-medium" :style="`color: ${headersContrast}`">{{
					meet.scheduleMeeting.infoHeader
				}}</v-col>
				<v-col cols="2" align="left"></v-col>
			</v-row>
			<v-row dense align="center" v-else>
				<v-col cols="12" class="row-format ml-3">
					<v-btn icon color="primary" @click="$emit('back')"><v-icon large>arrow_back</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		<v-container>
			<v-row>
				<v-col cols="12">
					<div class="mb-2" v-if="!hideDefaultNameEmail">
						<v-form v-model="isValid" ref="contactForm" lazy-validation>
							<div class="row-format">
								<v-text-field
									class="mr-1"
									hide-details
									dense
									outlined
									:label="$t('meetings.form.first-name')"
									:rules="required"
									v-model="firstName"
								></v-text-field>
								<v-text-field
									class="ml-1"
									hide-details
									dense
									outlined
									:label="$t('meetings.form.last-name')"
									:rules="required"
									v-model="lastName"
								></v-text-field>
							</div>
							<v-text-field
								type="email"
								class="my-2"
								hide-details
								dense
								outlined
								:label="$t('meetings.form.email')"
								:rules="emailRules"
								v-model="email"
							></v-text-field>
							<div  class="v-input form-input v-input--hide-details v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
								<div class="v-input__control">
									<div class="v-input__slot" style="padding: 0!important;">
										<div class="v-text-field__slot" style="padding: 0!important;">
											<fieldset  aria-hidden="true" :style="`border-radius: 4px!important; ${!phoneIsValid && doValidate ? 'border: 2px solid var(--v-error-base)!important;' : ''}`"><legend style="width: 0px;"><span class="notranslate">&ZeroWidthSpace;</span></legend></fieldset>
											<vue-tel-input
													:preferred-countries="preferredCountries"
													class="ml-0 custom-phone"
													@input="phoneUpdated"
													:inputOptions="{placeholder:$t('meetings.form.phone')}"
											></vue-tel-input>
										</div>
									</div>
								</div>
							</div>
						</v-form>
					</div>
					<div v-if="meet.discoveryTemplate">
						<meeting-form-v1
							ref="form"
							v-if="meet.discoveryTemplate.schemaVersion === 1"
							:template="meet.discoveryTemplate"
						></meeting-form-v1>
						<meeting-form-v2
							ref="form"
							v-if="meet.discoveryTemplate.schemaVersion === 2"
							:template="meet.discoveryTemplate"
							:meet="meet"
							:known-data="defaultContact"
						></meeting-form-v2>
					</div>
					<div
						v-if="meet.paymentEnabled && meet.stripeAccountId"
						class="mt-5 pa-3 text-left column-format"
						:style="`border: 1px solid var(--v-gray_60-base); border-radius: 4px; gap: 12px color: ${headersContrast}`"
					>
						<div class="font-14 pb-3">
							<div v-html="tokenizedText"></div>
						</div>
						<div id="payment-element"></div>
						<div id="error-message"></div>
					</div>
					<div :style="`display: flex; justify-content: ${alignButton()}`">
						<v-btn
							class="mt-5"
							:disabled="preview || buttonDisabled"
							elevation="0"
							:block="meet.scheduleMeeting.buttonStyle.buttonStyle === 'Block' ? true : null"
							:x-small="meet.scheduleMeeting.buttonStyle.buttonSize === 'X-Small' ? true : null"
							:small="meet.scheduleMeeting.buttonStyle.buttonSize === 'Small' ? true : null"
							:normal="meet.scheduleMeeting.buttonStyle.buttonSize === 'Normal' ? true : null"
							:large="meet.scheduleMeeting.buttonStyle.buttonSize === 'Large' ? true : null"
							:x-large="meet.scheduleMeeting.buttonStyle.buttonSize === 'X-Large' ? true : null"
							style="text-transform: none !important"
							:color="lightAccent"
							@click="scheduleMeeting"
						>
							<span :style="`letter-spacing:0.3px; color:${meet.pageLayout.accentColor}; font-weight:800`">{{
								meet.scheduleMeeting.scheduleText
							}}</span>
						</v-btn>
					</div>
					<div class="font-10 font-gray_70 mt-8">
						This page is protected by reCAPTCHA and the Google
						<a href="https://policies.google.com/privacy">Privacy Policy</a> and
						<a href="https://policies.google.com/terms">Terms of Service</a> apply.
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-snackbar v-model="showError" top color="gray_80" :timeout="5000">
			<span class="font-18 brand-medium">{{ errorText }}</span>
			<template v-slot:action="{ attrs }">
				<v-btn color="error" text v-bind="attrs" @click="showError = false"> OK </v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	import chroma from 'chroma-js';
	import MeetingFormV1 from '@/modules/meetings/MeetingFormV1';
	import MeetingFormV2 from '@/modules/meetings/MeetingFormV2';

	export default {
		name: 'MeetingForm',

		props: ['meet', 'preview', 'defaultContact', 'tokenMap','meeetingService','accountKey','stripe','paymentIntentKey'],

		components: { MeetingFormV2, MeetingFormV1 },

		data: function () {
			return {
				isInit: false,
				isValid: false,
				required: [(v) => !!v || 'Field is required'],
				emailRules: [
					(v) => !!v || 'Email is required',
					(v) =>
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
							v
						) || 'Please enter a valid email address',
				],

				firstName: null,
				lastName: null,
				email: null,
				phone: null,
				result: null,
				showError: false,
				errorText: null,
				buttonDisabled: false,
				elements: null,
				paymentElement: null,
				isPhoneValid: false,
				doValidate: false,
				stripePaymentIntent: null
			};
		},

		mounted() {
			if (this.defaultContact) {
				this.firstName = this.defaultContact.firstName;
				this.lastName = this.defaultContact.lastName;
				this.email = this.defaultContact.email;
				this.phone = this.defaultContact.phone;
			}
		},

		beforeDestroy() {},

		methods: {
			init: function(){
				if(!this.isInit){
					if(this.meet.paymentEnabled && this.meet.stripeAccountId){
						this.initStripe();
					}
				}
				this.isInit = true;
			},

			async initStripe(){
				let paymentIntentId = localStorage.getItem(this.paymentIntentKey);
				this.stripePaymentIntent = await this.meeetingService.getOrCreatePaymentIntent(this.meet.account.accountId,this.meet.id,paymentIntentId);
				this.$emit('stripe-payment-intent',this.stripePaymentIntent);

				const options = {
					clientSecret: this.stripePaymentIntent.clientSecret,
					appearance: {
						theme: this.stripeTheme,
						variables: {
							colorBackground: this.meet.pageLayout.backgroundMainColor,
							colorText: this.stripeContrast
						}
					}
				};

				this.elements = this.stripe.elements(options);
				this.paymentElement = this.elements.create('payment');
				this.paymentElement.mount("#payment-element");
			},

			handleResult(result) {
				this.result = result;
			},

			async scheduleMeeting() {
				this.buttonDisabled = true;
				let contactValid = this.hideDefaultNameEmail ? true : this.$refs.contactForm.validate();
				let formValid = true;
				this.doValidate = true;

				if (this.$refs.form) {
					formValid = this.$refs.form.validate();
				}

				if (!contactValid || !formValid || (!this.hideDefaultNameEmail && !this.phoneIsValid)) {
					this.errorText = 'Please correct the errors in your form';
					this.showError = true;
					this.enableButton();
					return;
				}

				let formResult = {
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email ? this.email.trim() : null,
					phone: this.phone ? this.phone.trim() : null,
				};

				this.setFormAnswers(formResult);

				if(this.meet.paymentEnabled && this.meet.stripeAccountId) {
					this.$store.commit('startLoading');

					try {
						this.stripePaymentIntent = await this.meeetingService.updatePaymentIntent(this.meet.account.accountId, this.stripePaymentIntent.id, formResult.email);
					}catch(err){
						console.log(err);
					}

					this.$emit('stripe-payment-intent', this.stripePaymentIntent);

					if(this.stripePaymentIntent.status === 'requires_payment_method') {
						const {error} = await this.stripe.confirmPayment({
							elements: this.elements,
							redirect: 'if_required'
						});

						this.$store.commit('stopLoading');

						if (error) {
							this.errorText = error.message;
							this.showError = true;
							this.enableButton();
							return;
						}
					}
				}

				this.$store.commit('stopLoading');
				this.$emit('form-result', formResult);
			},

			enableButton: function () {
				this.buttonDisabled = false;
			},

			setFormAnswers: function (formResult) {
				if (this.$refs.form) {
					this.$refs.form.setFormResult(formResult);
				}
			},

			hasFieldWithMapping: function (schema, schemaMapping) {
				for (let i = 0; i < schema.length; i++) {
					if (schema[i].schemaMapping === schemaMapping) {
						return true;
					} else if (schema[i].type === 'Container') {
						for (let j = 0; j < schema[i].columns.length; j++) {
							let column = schema[i].columns[j];
							for (let k = 0; k < column.items.length; k++) {
								if (column.items[k].schemaMapping === schemaMapping) {
									return true;
								}
							}
						}
					}
				}
				return false;
			},

			alignButton: function () {
				switch (this.meet.scheduleMeeting.buttonStyle.buttonAlignment) {
					case 'Left':
						return 'start';
					case 'Center':
						return 'center';
					case 'Right':
						return 'end';
					default:
						return 'center';
				}
			},

			phoneUpdated(number, object) {
				this.isPhoneValid = object.valid;
				this.phone = object.number;
			},
		},

		computed: {
			phoneIsValid: function(){
				if(this.isPhoneValid){
					return true;
				}else if(!this.phoneRequired && !this.phone){
					return true;
				}else{
					return false;
				}
			},

			preferredCountries: function() {
				let countries = ['US', 'CA', 'GB', 'IE', 'AU', 'NZ'];
				return countries;
			},

			tokenizedText: function () {
				let result = this.meet.requiredPayment.paymentInfo;

				this.tokenMap.forEach((value, key) => (result = result.replaceAll('{{' + key + '}}', value)));

				return result;
			},

			phoneRequired: function () {
				if (this.meet.location.type === 'PhoneIn' || this.meet.location.type === 'PhoneOut') {
					return true;
				} else {
					return false;
				}
			},

			hideDefaultNameEmail: function () {
				if (this.meet.discoveryTemplate && this.meet.discoveryTemplate.schemaVersion === 2) {
					let foundFirst = this.hasFieldWithMapping(this.meet.discoveryTemplate.schemaV2, 'firstName');
					let foundEmail = this.hasFieldWithMapping(this.meet.discoveryTemplate.schemaV2, 'email');
					return foundFirst && foundEmail;
				} else {
					return false;
				}
			},

			headersContrast: function () {
				let black = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return '#000000';
				} else {
					return '#FFFFFF';
				}
			},

			lightAccent: function () {
				return chroma(this.meet.pageLayout.accentColor).alpha(0.18).hex();
			},

			stripeTheme: function () {
				let black = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return 'stripe';
				} else {
					return 'night';
				}
			},

			stripeContrast: function () {
				let black = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return '#3C3F44';
				} else {
					return '#FFFFFF';
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
