export default {
	light: {
		primary: '#565451' /* Royal Blue */,

		black: '#000000' /* Black */,
		white: '#FFFFFF' /* White */,

		gray_10: '#f8f9fa',
		gray_20: '#ebedef' ,
		gray_30: '#cfd3d7' ,
		gray_50: '#bec4ca' ,
		gray_60: '#97a1ab' ,
		gray_70: '#828a92' ,
		gray_80: '#666d73',
		gray_90: '#3c3f44' ,

		// gray: '#10110F',
		bali_hai: '#8EA3B8',
		sundown: '#FFA5B6',
		heliothrope: '#CE62E9',
		cornflower: '#7950F2',
		dodger: '#4F72FF',
		turquoise: '#3BDBBE',
		emerald: '#51CF66',
		sunglow: '#FEBB37',
		pumpkin: '#FD7E14',
		carnation: '#FA5252',

	},
	dark: {
		primary: '#F4F3EF' /* Royal Blue DK */,

		black: '#FFFFFF' /* Black */,
		white: '#181818' /* White */,

		gray_10: '#10110F',
		gray_20: '#2F2E2C',
		gray_30: '#56524E',
		gray_50: '#56524E',
		gray_60: '#D6D1CB',
		gray_70: '#E9E8E0',
		gray_80: '#F4F3EF',
		gray_90: '#F9F8F6',

		// gray: '#F9F8F6' /* gray 90 */,
		bali_hai: '#9FB2C5',
		sundown: '#F06595',
		heliothrope: '#CE62E9',
		cornflower: '#9775FA',
		dodger: '#748FFC',
		turquoise: '#099268',
		emerald: '#5C940D',
		sunglow: '#F59F00',
		pumpkin: '#E8590C',
		carnation: '#FF8787',
	},
};
