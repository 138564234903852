import countriesSimple from '@/assets/data/countries-simple.json';
import countries from '@/assets/data/countries.json';

export default class Countries {
	getCountriesSimple() {
		return countriesSimple;
	}
	getCountries() {
		return countries;
	}
	codeToName(code) {
		if (!code) return '';
		let country = code;
		let tmp = countriesSimple.find((c) => c.code === code);
		if (tmp) country = tmp.name;
		return country;
	}
	static getCodeFromLocale() {
		console.log(navigator.language.slice(-2));
		console.log(navigator);
		return navigator.language.slice(-2);
	}
}
