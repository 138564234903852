import { render, staticRenderFns } from "./DateSelector.vue?vue&type=template&id=8c3416c2&"
import script from "./DateSelector.vue?vue&type=script&lang=js&"
export * from "./DateSelector.vue?vue&type=script&lang=js&"
import style0 from "./DateSelector.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDatePicker,VIcon,VMenu,VTextField})
