import axios from 'axios';

export default class PublicFormService {
	constructor(podUrl) {
		this.podUrl = podUrl;
	}

	getClient(baseUrl) {
		const options = {
			baseURL: baseUrl,
			withCredentials: false,
		};
		return axios.create(options);
	}

	getFormInfo(urlPath, uniqueTemplateId) {
		let params = {
			urlPath: urlPath,
			uniqueTemplateId: uniqueTemplateId,
		};

		return this.getClient(this.podUrl)
			.get('/form/info', { params: params })
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	getFormSubmission(token){
		return this.getClient(this.podUrl)
			.get('/form/submission', { params: {token:token} })
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	submitFormInfo(accountId, uniqueTemplateId, formSubmission, files, reCaptchaToken) {
		let formData = new FormData();

        formData.append("accountId",accountId);
        formData.append("uniqueTemplateId",uniqueTemplateId);
		formData.append('formSubmission', JSON.stringify(formSubmission));
		formData.append("reCaptchaToken",reCaptchaToken);
		files.forEach((a) => {
			formData.append('files', a);
		});

		return this.getClient(this.podUrl)
			.post('/form/submission', formData, {
                headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}
}
