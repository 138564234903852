<template>
	<div>
		<div v-for="field in schema" :key="field.id" class="text-left my-4">
			<component
					ref="inputField"
					:key="field.id"
					:is="field.fieldType"
					:value="formData[field.name]"
					:preview-mode="previewMode"
					@input="updateForm(field.id, $event)"
					v-bind="field"
			></component>
		</div>
	</div>
</template>

<script>
	import CheckboxInput from '@/modules/forms/v1/renderer/CheckboxInput';
	import RadioInput from '@/modules/forms/v1/renderer/RadioInput';
	import SelectInput from '@/modules/forms/v1/renderer/SelectInput';
	import TextBlock from '@/modules/forms/v1/renderer/TextBlock';
	import TextInput from '@/modules/forms/v1/renderer/TextInput';
	import LeadContact from "@/modules/forms/v1/renderer/LeadContact";
	import DateInput from "@/modules/forms/v1/renderer/DateInput";
	import FileInput from "@/modules/forms/v1/renderer/FileInput";

	export default {
		name: 'Renderer',

		props: ['schema'],

		components: { CheckboxInput, RadioInput, SelectInput, TextBlock, TextInput, LeadContact, DateInput, FileInput },

		data: function () {
			return {
				formData: this.value || {},
				previewMode: false
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			validate: function(){
				let invalidCount = 0;
				for(let i=0; i < this.schema.length; i++){
					if(!this.$refs.inputField[i].validate()){
						invalidCount++
					}
				}
				if(invalidCount){
					return false;
				}else{
					return true;
				}
			},

			updateForm(id, value) {
				this.$set(this.formData, id, value);
				this.$emit('input', this.formData);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
