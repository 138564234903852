import axios from 'axios';

export default class PublicMeetingService {
	constructor(podUrl) {
		this.podUrl = podUrl;
	}

	getClient(baseUrl) {
		const options = {
			baseURL: baseUrl,
			withCredentials: false,
		};
		return axios.create(options);
	}

	getOrCreatePaymentIntent(accountId,meetingId,paymentIntentId){
		let params = {
			accountId: accountId,
			id: meetingId,
			paymentIntentId: paymentIntentId
		};

		return this.getClient(this.podUrl)
			.get('/meeting/stripePaymentIntent', { params: params })
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	}

	updatePaymentIntent(accountId,paymentIntentId,email){
		let formData = new FormData();

		formData.append('accountId', accountId);
		formData.append('paymentIntentId',paymentIntentId);
		formData.append('email',email);

		return this.getClient(this.podUrl)
			.put('/meeting/stripePaymentIntent', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	}

	getMeetingInfo(urlPath, meetingId, start, end, conflictCheck) {
		let params = {
			urlPath: urlPath,
			meetingId: meetingId,
			start: start,
			end: end,
			conflictCheck: conflictCheck
		};

		return this.getClient(this.podUrl)
			.get('/meeting/info', { params: params })
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	getMeetingAvailability(accountId, id, start, end, conflictCheck) {
		let params = {
			accountId: accountId,
			id: id,
			start: start,
			end: end,
			conflictCheck: conflictCheck
		};

		return this.getClient(this.podUrl)
			.get('/meeting/availability', { params: params })
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	scheduleMeeting(scheduleRequest, files, reCaptchaToken, conflictCheck) {
		let formData = new FormData();

		formData.append('scheduleRequest', JSON.stringify(scheduleRequest));
		formData.append('reCaptchaToken',reCaptchaToken);

		if(conflictCheck === false){
			formData.append('conflictCheck','false');
		}

		if(files) {
			files.forEach((a) => {
				formData.append('files', a);
			});
		}

		return this.getClient(this.podUrl)
			.post('/meeting/schedule', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	rescheduleMeeting(id, confirmedTime) {
		return this.getClient(this.podUrl)
			.post(`/meeting/existing/${id}`, confirmedTime)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	cancelMeeting(id, reason) {
		return this.getClient(this.podUrl)
			.delete(`/meeting/existing/${id}?reason=${encodeURIComponent(reason)}`)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	getExistingMeeting(id, start, end) {
		let params = {
			start: start,
			end: end,
		};
		return this.getClient(this.podUrl)
			.get(`/meeting/existing/${id}`, { params: params })
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}
}
