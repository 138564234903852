<template>
	<div
		v-if="form"
		:class="`${inFrame ? 'outer-wrapper-in-frame' : 'outer-wrapper'} row-format centered`"
		:style="`font-family: ${font}; --background-color: ${form.accentColor}`"
	>
		<v-container :class="`${inFrame ? 'main-wrapper-in-frame' : 'main-wrapper'}`" v-if="form">
			<v-row v-if="form.showLogo && form.accountLogo">
				<v-col cols="12">
					<img :src="form.accountLogo" style="max-height: 100px; max-width: 250px" />
				</v-col>
			</v-row>
			<v-row v-if="!complete" dense>
				<v-col cols="12">
					<div class="mx-4">
						<renderer ref="renderer" :schema="form.schema" @input="handleResult"></renderer>
					</div>
				</v-col>
				<v-col cols="12">
					<div class="mx-8 mt-4">
						<v-btn width="150" :color="form.accentColor" elevation="0" @click="saveForm"
							><span
								:style="`font-weight: 600; color: ${form.buttonText}!important; text-transform:none!important`"
								>{{ $t('global.submit') }}</span
							></v-btn
						>
						<div class="font-10 font-gray_70 mt-8">
							This page is protected by reCAPTCHA and the Google
							<a href="https://policies.google.com/privacy">Privacy Policy</a> and
							<a href="https://policies.google.com/terms">Terms of Service</a> apply.
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12">{{ $t('discovery.complete') }}</v-col>
			</v-row>
		</v-container>

		<v-snackbar v-model="showError" top color="gray_80" :timeout="5000">
			{{ errorText }}
			<template v-slot:action="{ attrs }">
				<v-btn color="error" text v-bind="attrs" @click="showError = false"> OK </v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	import Renderer from '@/modules/forms/v1/renderer/Renderer';

	export default {
		name: 'FormV1',

		props: ['form', 'font', 'inFrame','complete'],

		components: { Renderer },

		data: function () {
			return {
				result: null,
				showError: false,
				errorText: null,
				showSubmitButton: true,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			saveForm: function () {
				if (!this.$refs.renderer.validate()) {
					this.errorText = 'Please correct the errors in your form';
					this.showError = true;
					return false;
				}

				let formResult = {};

				let answers = [];
				let files = [];
				let keys = Object.keys(this.result);

				for (let i = 0; i < keys.length; i++) {
					let id = keys[i];
					let schemaIndex = this.form.schema.findIndex((s) => s.id === keys[i]);
					let schemaItem = this.form.schema.find((s) => s.id === keys[i]);

					if (schemaItem.fieldType === 'LeadContact') {
						let contactInfo = this.result[keys[i]];
						Object.keys(contactInfo).forEach((p) => {
							if (contactInfo[p]) {
								formResult[p] = contactInfo[p];
							}
						});
					} else {
						let answer;
						let question = schemaItem.htmlText;

						if (schemaItem.fieldType === 'FileInput') {
							let fileResult = this.result[keys[i]];
							files.push(...fileResult);
							answer = JSON.stringify(files.map((f) => f.name));
						} else if (Array.isArray(this.result[keys[i]])) {
							answer = this.result[keys[i]].join(',');
						} else {
							answer = this.result[keys[i]];
						}

						answers.push({
							index: schemaIndex,
							id: id,
							question: question,
							answer: answer,
							fieldKey: schemaItem.fieldKey,
							fieldType: schemaItem.fieldType,
						});
					}
				}

				answers.sort((a, b) => a.index - b.index);
				formResult.answers = answers;

				this.$emit('complete', {
					formResult: formResult,
					files: files,
				});
			},

			handleResult: function (result) {
				this.result = result;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.outer-wrapper {
		background-color: var(--v-gray_20-base);
		min-height: 100vh;
	}

	.outer-wrapper-in-frame {
	}

	.main-wrapper {
		//box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 2px;
		max-width: 900px;
		margin: 20px;
		padding: 24px;
		height: fit-content;
		background-color: var(--v-white-base);
	}

	.main-wrapper-in-frame {
		//border: 1px solid var(--v-gray_50-base);
		//border-radius: 2px;
		//margin: 5px;
		//background-color: var(--v-white-base);
	}
</style>
