<template>
	<v-container>
		<v-row dense v-if="meet.pageLayout.showLogo">
			<v-col cols="12" align="left" class="">
				<img :src="meet.account.accountLogo" style="max-width: 80%; max-height: 150px">
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" align="left">
				<div style="line-height: 1.8;" v-html="tokenizedText"></div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { Settings } from 'luxon';
  import timezones from '@/assets/data/timezones.json';

	export default {
		name: 'MeetingInfo',

		props: ['meet', 'confirmedTime','action', 'tokenMap'],

		components: {},

		data: function () {
			return {
				Settings:Settings,
        timezones: timezones,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {

    },

		computed: {
			tokenizedText: function() {
				let result = this.meet.scheduleMeeting.schedulerInfo;
				this.tokenMap.forEach((value, key) => (result = result.replaceAll('{{' + key + '}}', value)));
				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
