<template>
	<div class="my-5" id="meeting-schedule" :style="`color: ${textContrast}`">
		<div>
			<div class="row-format centered mt-1 mb-2">
				<div>
					<v-btn icon :disabled="disablePrevious" @click="incrementMonth(-1)"><v-icon size="30" :style="`color: ${textContrast}`">$prev</v-icon></v-btn>
				</div>
				<div class="brand-medium px-4">
					{{ DateTime.fromISO(monthToShow).toLocaleString({ month: 'long', year: 'numeric' }) }}
				</div>
				<div>
					<v-btn icon :disabled="disableNext"><v-icon size="30" @click="incrementMonth(1)" :style="`color: ${textContrast}`">$next</v-icon></v-btn>
				</div>
			</div>
			<v-date-picker
				class="schedule-picker"
				:min="minDate"
				full-width
				:picker-date="monthToShow"
				:style="`--date-color: ${dateContrast}; --month-header-color: ${monthHeadersContrast}; --dp-font-family: ${meet.pageLayout.fontFamily}; --dp-background-color: ${meet.pageLayout.backgroundMainColor};--light-accent:${lightAccent}; --accent:${meet.pageLayout.accentColor};`"
				:no-title="true"
				@change="dateClicked"
				:allowed-dates="hasAvailability"
				:first-day-of-week="$store.state.firstDayOfWeek"
			></v-date-picker>
		</div>
		<div v-if="availableSlots.length" class="mt-3">
			<div class="brand-medium my-2">{{ selectedDate.toLocaleString({ month: 'long', day: 'numeric' }) }}</div>
			<div class="row-format" style="flex-wrap: wrap">
				<div
					v-for="(slot, index) in availableSlots"
					:key="index"
					@click="selectTimeSlot(slot)"
					class="slot pointer row-format centered"
					:style="`--background-color: ${meet.pageLayout.buttonColor};--accent-color: ${meet.pageLayout.accentColor}; --contrast-color: ${contrast}; --light-accent:${lightAccent}`"
				>
					<div :class="`pa-1 ${selectedSlot && selectedSlot.start === slot.start ? 'selected' : ''}`">
						{{ DateTime.fromISO(slot.start).toLocaleString(DateTime.TIME_SIMPLE) }}
					</div>
					<div
						@click.stop="timeConfirmed"
						class="selected selected-slot pa-1"
						:style="`overflow: hidden; ${
							selectedSlot && selectedSlot.start === slot.start
								? 'visibility:visible; width:50%'
								: 'visibility:hidden; width:0px'
						}`"
					>
            {{ meet.scheduleMeeting.confirmText }}
					</div>
				</div>
			</div>
		</div>
		<v-menu :close-on-click="true" :close-on-content-click="false" v-model="timezoneMenu" top max-width="350">
			<template v-slot:activator="{ on }">
				<div class="mt-5 font-12 pointer" v-on="on">* {{ $t('meetings.times-in') }} [{{ Settings.defaultZoneName }}]</div>
			</template>
			<div style="background-color: var(--v-white-base)" class="column-format gap-1 pa-5 text-left">
				<v-text-field v-model="filter" hide-details dense outlined label="Filter..."></v-text-field>
				<div style="max-height: 300px; overflow-y: auto" class="mt-2">
					<div
						v-for="timezone in filteredTimeZones"
						:key="timezone"
						class="font-gray_80 pointer"
						@click="setTimeZone(timezone)"
					>
						{{ timezone }}
					</div>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import { DateTime, Settings } from 'luxon';
	import chroma from 'chroma-js';
	import timezones from '@/assets/data/timezones.json';

	export default {
		name: 'MeetingSchedule',

		props: ['meet', 'start', 'meetingService', 'conflictCheck'],

		components: {},

		data: function () {
			return {
				timezones: timezones,
				DateTime: DateTime,
				Settings: Settings,
				selectedDate: null,
				selectedSlot: null,
				monthToShow: DateTime.now().toISODate(),
				filter: null,
				timezoneMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			timeConfirmed: function () {
				this.$emit('time-confirmed', this.selectedSlot);
			},

			setTimeZone: function (timezone) {
				Settings.defaultZoneName = timezone;
				this.timezoneMenu = false;
				this.incrementMonth(0);
			},

			incrementMonth: function (count) {
				let monthStart = DateTime.fromISO(this.monthToShow).startOf('month').plus({ months: count });
				let monthEnd = monthStart.plus({ months: 1 }).minus({ seconds: 1 });

				this.monthToShow = monthStart.toISODate();
				this.selectedSlot = null;
				this.selectedDate = null;
				this.$store.commit('startLoading');

				this.meetingService
					.getMeetingAvailability(
						this.meet.account.accountId,
						this.meet.id,
						monthStart.toISODate(),
						monthEnd.toISODate(),
						this.conflictCheck
					)
					.then((res) => {
						this.meet.availableMeetings.splice(0, this.meet.availableMeetings.length);
						this.meet.availableMeetings.push(...res.data);
						this.$store.commit('stopLoading');
					});
			},

			selectTimeSlot: function (slot) {
				this.selectedSlot = slot;
			},

			dateClicked: function (date) {
				this.selectedDate = DateTime.fromISO(date).startOf('day');
				this.selectedSlot = null;
			},

			hasAvailability(dateString) {
				let date = DateTime.fromISO(dateString).startOf('day');
				if (this.availableDates.findIndex((d) => d.toMillis() === date.toMillis()) > -1) {
					return true;
				} else {
					return false;
				}
			},

			suffix: function (num) {
				let j = num % 10,
					k = num % 100;
				if (j == 1 && k != 11) {
					return `${num}st`;
				} else if (j == 2 && k != 12) {
					return `${num}nd`;
				} else if (j == 3 && k != 13) {
					return `${num}rd`;
				} else {
					return `${num}th`;
				}
			},
		},

		computed: {
			filteredTimeZones: function () {
				let result = [...timezones];
				return result.filter((t) => {
					if (this.filter) {
						return t.includes(this.filter);
					} else {
						return true;
					}
				});
			},
			disablePrevious: function () {
				let m = DateTime.fromISO(this.monthToShow).startOf('month');
				let t = DateTime.now().startOf('month');
				if (t < m) {
					return false;
				} else {
					return true;
				}
			},

			disableNext: function () {
				if (this.meet.maxMeetingDate) {
					let m = DateTime.fromISO(this.monthToShow).endOf('month');
					let t = DateTime.fromISO(this.meet.maxMeetingDate).endOf('month');
					return t <= m;
				} else {
					return false;
				}
			},

			minDate: function () {
				return DateTime.now().toISODate();
			},

			contrast: function () {
				let black = chroma.contrast(this.meet.pageLayout.accentColor, '#000000');
				let white = chroma.contrast(this.meet.pageLayout.accentColor, '#FFFFFF');
				if (black > white) {
					return '#000';
				} else {
					return '#FFF';
				}
			},

      textContrast: function() {
        let black = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#000000');
        let white = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#FFFFFF');
        if (black > white) {
          return '#000';
        } else {
          return '#FFF';
        }
      },

      monthHeadersContrast: function() {
        let black = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#000000');
        let white = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#FFFFFF');
        if (black > white) {
          return '#00000061';
        } else {
          return '#FFFFFFA0';
        }
      },

      dateContrast: function() {
        let black = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#000000');
        let white = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#FFFFFF');
        if (black > white) {
          return '#00000042';
        } else {
          return '#FFFFFF70';
        }
      },

			lightAccent: function () {
				return chroma(this.meet.pageLayout.accentColor).alpha(0.18).hex();
			},

			availableSlots: function () {
				let result = [];
				if (this.selectedDate) {
					this.meet.availableMeetings.forEach((m) => {
						if (DateTime.fromISO(m.start).startOf('day').toISODate() === this.selectedDate.toISODate()) {
							result.push(m);
						}
					});
				}
				return result;
			},

			availableDates: function () {
				let dates = [];
				this.meet.availableMeetings.forEach((m) => {
					let date = DateTime.fromISO(m.start).startOf('day');
					let ix = dates.findIndex((d) => d.toMillis() === date.toMillis());
					if (ix === -1) {
						dates.push(date);
					}
				});
				return dates;
			},
		},
	};
</script>

<style lang="scss">
	#meeting-schedule {
    .v-date-picker-header {
      display: none !important;
    }

    .v-date-picker-table--date__week{
      color: var(--contrast-color);
    }

    .v-date-picker-table {
      height: unset !important;
      background-color: var(--dp-background-color);
      font-family: var(--dp-font-family) !important;

      th {
        color: var(--month-header-color);
      }

      td {
        padding-bottom: 8px;
      }

      .v-btn {
        .v-btn--disabled.v-icon {

        }
        &:not(.v-btn--disabled) {
          font-weight: 800;
          background-color: var(--light-accent);
          .v-btn__content {
            font-family: var(--dp-font-family);
            color: var(--accent) !important;
          }
        }
        &:is(.v-btn--disabled) {
          .v-btn__content {
            font-family: var(--dp-font-family);
            color: var(--date-color);
          }
        }
      }
    }

		.slot {
			min-width: 180px;
			flex: 1 1 0px;
			border: 1px solid var(--v-gray_30-base);
			border-radius: 4px;
			margin: 4px;
			color: var(--accent-color);
			font-weight: 600;
			transition: all 150ms ease;
      background-color: var(--background-color);

			&:hover {
				box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;
			}
		}

		.selected {
			width: 50%;
		}

		.selected-slot {
			background-color: var(--light-accent);
			color: var(--accent-color);
			transition: all 150ms ease;
		}

		.dot-class {
			border-radius: 20px;
			width: 20px;
			height: 20px;
		}
	}
</style>
