<template>
	<v-app data-app id="app">
		<div style="width:100%; height: 100%;">
			<router-view />
		</div>
		<v-overlay style="z-index: 1000" :value="$store.state.loadingState">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-app>
</template>

<script>
	import {Settings as LuxonSettings} from 'luxon';

	export default {
		name: 'App',
		components: {},

		data() {
			return {

			};
		},

		mounted() {
			if (navigator.languages && navigator.languages.length) {
				this.$store.state.locale = navigator.languages[0];
				LuxonSettings.defaultLocale = navigator.languages[0];
			}
			try {
				this.$store.state.firstDayOfWeek = new Intl.Locale(navigator.language).weekInfo.firstDay === 7 ? 0 : 1;
			}catch(err){
				console.log(err);
			}
		},

		computed: {},

		watch: {},
	};
</script>

<style lang="scss">
	@import './assets/css/brand.scss';
</style>