<template>
	<v-container style="max-width: 500px">
		<v-row dense>
			<v-col v-if="meet.pageLayout.showLogo" cols="12">
        <img :src="meet.account.accountLogo" style="max-width: 300px" />
			</v-col>
			<v-col cols="12">
				<div v-html="tokenizedText" />
			</v-col>
			<v-col cols="12" class="mt-3" v-if="!confirmedMeeting.googleMeeting">
				<v-btn
					class="mr-2"
					elevation="0"
					style="text-transform: none !important"
					:color="lightAccent"
					@click="addToGoogle"
				>
					<span :style="`letter-spacing:0.3px; color:${meet.pageLayout.accentColor}; font-weight:800`">
						{{ meet.confirmMeeting.addToGoogleText }}
					</span>
				</v-btn>

				<v-btn
					class="ml-2"
					elevation="0"
					style="text-transform: none !important"
					:color="lightAccent"
					@click="downloadIcs"
				>
					<span :style="`letter-spacing:0.3px; color:${meet.pageLayout.accentColor}; font-weight:800`">
						{{ meet.confirmMeeting.addToOtherText }}
					</span>
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { Settings } from 'luxon';
	import chroma from 'chroma-js';

	export default {
		name: 'MeetingConfirmation',

		props: ['meet', 'confirmedMeeting', 'podUrl', 'action', 'tokenMap'],

		components: {},

		data: function () {
			return {
				Settings: Settings,
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			addToGoogle: function () {
				window.open(`${this.podUrl}/meeting/invitee/${this.confirmedMeeting.id}/google`, '_blank').focus();
			},

			downloadIcs: function () {
				window.open(`${this.podUrl}/meeting/invitee/${this.confirmedMeeting.id}/ics`, '_blank').focus();
			},
		},

		computed: {
			tokenizedText: function () {
				let result = this.meet.confirmMeeting.confirmInfo;
				this.tokenMap.forEach((value, key) => (result = result.replaceAll('{{' + key + '}}', value)));
				return result;
			},

			lightAccent: function () {
				return chroma(this.meet.pageLayout.accentColor).alpha(0.18).hex();
			},
		},
	};
</script>

<style scoped lang="scss"></style>
