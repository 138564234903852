import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingState: false,
    locale: 'en-US',
    firstDayOfWeek: 0,
  },
  mutations: {
    startLoading(state){
      state.loadingState = true;
    },

    stopLoading(state){
      state.loadingState = false;
    },
  },
  actions: {
  },
  modules: {
  }
})
