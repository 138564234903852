<template>
	<div class="px-2">
		<renderer ref="renderer" :schema="template.schema" @input="handleResult"></renderer>
	</div>
</template>

<script>
	import Renderer from '@/modules/forms/v1/renderer/Renderer';

	export default {
		name: 'MeetingFormV1',

		props: ['template'],

		components: { Renderer },

		data: function () {
			return {
				result: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleResult: function (result) {
				this.result = result;
			},

			validate: function () {
				return this.$refs.renderer.validate();
			},

			setFormResult: function (formResult) {
				let answers = [];
				let files = [];

				if (this.result && this.template) {
					let keys = Object.keys(this.result);

					for (let i = 0; i < keys.length; i++) {
						let id = keys[i];
						let schemaItem = this.template.schema.find((s) => s.id === keys[i]);
						let schemaIndex = this.template.schema.findIndex((s) => s.id === keys[i]);

						if (schemaItem.fieldType === 'LeadContact') {
							let contactInfo = this.result[keys[i]];
							Object.keys(contactInfo).forEach((p) => {
								if (contactInfo[p]) {
									formResult[p] = contactInfo[p];
								}
							});
						} else {
							let answer;
							let question = schemaItem.htmlText;

							if (schemaItem.fieldType === 'FileInput') {
								let fileResult = this.result[keys[i]];
								files.push(...fileResult);
								answer = JSON.stringify(fileResult.map((f) => f.name));
							} else if (Array.isArray(this.result[keys[i]])) {
								answer = this.result[keys[i]].join(',');
							} else {
								answer = this.result[keys[i]];
							}

							answers.push({
								index: schemaIndex,
								id: id,
								question: question,
								answer: answer,
								fieldKey: schemaItem.fieldKey,
								fieldType: schemaItem.fieldType,
							});
						}
					}
				}

				answers.sort((a, b) => a.index - b.index);
				formResult.answers = answers;
				formResult.files = files;
				return formResult;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
