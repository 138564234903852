<template>
	<v-container style="max-width: 500px">
		<v-row dense>
			<v-col v-if="meet.pageLayout.showLogo" cols="12">
				<img :src="meet.account.accountLogo" style="max-width: 300px" />
			</v-col>
			<v-col cols="12">
				<div v-html="tokenizedText" />
			</v-col>
			<v-col cols="12" class="mt-3">
				<div v-if="!cancelled">
					<v-textarea
						id="cancel-area"
						class="cancel-area"
						:style="`font-size: 14px; background-color: ${meet.pageLayout.backgroundMainColor}; --text-contrast: ${textContrast}`"
						outlined
						persistent-placeholder
						:label="meet.cancelMeeting.cancellationReasonText"
						v-model="cancellationReason"
						rows="3"
					></v-textarea>
					<v-btn
						class="mr-2"
						elevation="0"
						style="text-transform: none !important"
						:color="lightAccent"
						@click="cancel"
					>
						<span :style="`letter-spacing:0.3px; color:${meet.pageLayout.accentColor}; font-weight:800`">
							{{ meet.cancelMeeting.cancelText }}
						</span>
					</v-btn>
				</div>

				<div :style="`color: ${textContrast}`" v-else>{{ meet.cancelMeeting.postCancellationText }}</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import chroma from 'chroma-js';
	import PublicMeetingService from '@/modules/meetings/PublicMeetingService';

	export default {
		name: 'MeetingConfirmation',

		props: ['meet', 'confirmedMeeting', 'podUrl', 'tokenMap'],

		components: {},

		data: function () {
			return {
				cancellationReason: null,
				cancelled: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			cancel: function () {
				this.$store.commit('startLoading');
				new PublicMeetingService(this.podUrl)
					.cancelMeeting(this.confirmedMeeting.id, this.cancellationReason)
					.then(() => {
						this.cancelled = true;
						window.parent.postMessage('MEETING_CANCELLED', '*');
					})
					.catch((err) => this.$emit('error', err))
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			tokenizedText: function () {
				let result = this.meet.cancelMeeting.cancelInfo;
				this.tokenMap.forEach((value, key) => (result = result.replaceAll('{{' + key + '}}', value)));
				return result;
			},

			lightAccent: function () {
				return chroma(this.meet.pageLayout.accentColor).alpha(0.18).hex();
			},

			textContrast: function () {
				let black = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meet.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return '#000';
				} else {
					return '#FFF';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.cancel-area::v-deep {
		textarea {
			color: var(--text-contrast) !important;
		}

		.v-label {
			color: var(--text-contrast) !important;
		}

		.outlined {
			color: var(--text-contrast) !important;
		}
	}
	.v-textarea.v-text-field--enclosed {
		color: var(--text-contrast) !important;
	}
</style>
