<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
		:style="styles"
		@mouseover="hovering = true"
		@mouseleave="hovering = false"
	>
		<use :id="id" :xlink:href="href"></use>
	</svg>
</template>

<script>
	export default {
		name: 'HIcon',
		props: {
			size: String, // In pixels
			name: { type: String, required: true },
			id: String, // id of "use" block for direct styling
			color: String,
			twoColor: { type: Boolean, default: false },
			tooltip: { type: String, required: false, default: null },
		},

		data() {
			return {
				hovering: false,
				clicking: false,
			};
		},

		computed: {
			href() {
				return '/symbol-defs.svg#' + this.name;
			},
			styles() {
				let str = 'outline: none !important; ';
				if (this.size) {
					str += 'height:' + this.size + 'px;width:' + this.size + 'px;';
				}
				if (this.color) {
					str += 'color:' + this.color;
				}
				return str;
			},
		},

		mounted() {},
	};
</script>

<style lang="scss" scoped>
	svg {
		fill: currentColor;
		stroke-width: 0; // Set to prevent fill-based SVGs from getting stroke color. If explicitly set on SVG, it's ignored.
		stroke: currentColor;
	}
</style>
