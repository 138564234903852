<template>
	<div v-if="formSubmission">
		<form-v2 :form="form" :font="font" :in-frame="false" :in-portal="false" :complete="false" ref="form" @complete="saveForm($event)" :disable-partial-data="true"></form-v2>
	</div>
</template>

<script>
import PublicFormService from "@/modules/forms/PublicFormService";
import FormV2 from "@/modules/forms/v2/FormV2";
import WebFontLoader from "webfontloader";

export default {
	name: "CompletedForm",

	props: ['podId'],

	components: {FormV2},

	data: function () {
		return {
			formSubmission: null,
			form: null,
			font: null,
			results: [],
		}
	},

	mounted() {
		this.getFormSubmission();
	},

	beforeDestroy() {
	},

	methods: {
		saveForm: function(value){
			console.log(value);
		},

		getFormSubmission: function(){
			new PublicFormService(this.podUrl).getFormSubmission(this.token).then((res) => {
				this.formSubmission = res.data;
				this.form = this.formSubmission.baseDiscovery;
				this.font = this.form.font;
				this.loadFont(this.form.font);
				this.fixupResults();
			})
		},

		fixupResults: function(){
			let items = this.form.schemaV2.filter(this.isInputType);
			let formData = this.formSubmission.formData;
			let index = 0;
			let currentPage = 0;

			for(let i=0; i < items.length; i++){
				let item = items[i];

				if(item.type === 'NewPage'){
					index = 0;
					currentPage = currentPage+1;
					continue;
				}

				if(item.type === 'Container'){
					for(let j=0; j < item.columns.length; j++){
						let columnSchemaItems = item.columns[j].items.filter(this.isInputType);
						for(let k=0; k < columnSchemaItems.length; k++){
							let resultIndex = (index++) + ((currentPage + 1) * 1000)
							this.processItem(formData,columnSchemaItems[k],resultIndex);
						}
					}
				}else{
					let resultIndex = (index++) + ((currentPage + 1) * 1000)
					this.processItem(formData,item,resultIndex);
				}
			}
			this.$nextTick(() => this.$refs.form.setResults(this.results));
		},

		processItem(formData,item,resultIndex){
			let value = null;

			let answer = formData.answers.find(a => a.id === item.id);

			if(answer){
				if(item.type === 'Checkbox') {
					value = [];
					if (answer.answer) {
						answer.answer.split(",").forEach(a => value.push(a.trim()));
					}
				}else if(item.type === 'FileInput'){
					answer.answer = JSON.parse(answer.answer);
					value = [];
					for(let i=0; i < answer.answer.length; i++){
						let fileName = answer.answer[i];
						let s3File = this.formSubmission.files.find(f => f.fileName === fileName);
						value.push(s3File);
					}
				}else {
					value = answer.answer;
				}
			}else if(item.schemaMapping) {
				value = formData[item.schemaMapping];
			}

			this.results.push({
				item: item,
				index: resultIndex,
				value: value,
			});
		},

		isInputType(item){
			let type = item.type;
			return type === 'FileInput' || type === 'Checkbox' || type === 'DateInput' || type === 'Radio' || type === 'Select' || type === 'TextArea' || type === 'TextInput' || type === 'Container' || type === 'NewPage';
		},

		loadFont: function(fontName){
			WebFontLoader.load({
				google: {
					families: [`${fontName}:200,300,400,500,600`],
				},
				active: this.setFont,
			});
		},
	},

	computed: {
		podUrl: function () {
			return 'https://pod' + this.podId + '.' + process.env.VUE_APP_BASE_URL + '/api';
		},

		token: function(){
			return this.$route.query.token;
		}
	},

}
</script>

<style lang="scss">
	.v-input {
		textarea {
			color: var(--v-black-base)!important;
		}
		input {
			color: var(--v-black-base)!important;
		}
	}
</style>